import { useCallback, useState } from "react";

import { filter, isArray, isEmpty } from "lodash";

import {
  DEFAULT_MANAGED_TASKS_STATES,
  DEFAULT_UNMANAGED_TASKS_STATES,
  TasksGroups,
} from "components/dashboard/hooks/tasks";
import { UNASSIGNED_ID } from "components/dashboard/utils";
import moment from "moment";
import { parseDate } from "utils/datetime-utils";
import { extractUuidFromUrl } from "utils/uuid-utils";

const useStoreFilters = (filterKey, onApplyRemoteFilters, tasks: TasksGroups[]) => {
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    active: {
      states: [...DEFAULT_MANAGED_TASKS_STATES],
      datetime: [],
      metaFields: [],
      assignees: [],
    },
  });

  const onApply = (action) => {
    setLoading(true);
    switch (action.type) {
      case "FILTER_BY_STATES":
        filters.active.states = action.states;

        setFilters({ ...filters });
        const unManagedStates = filters.active.states.filter((state) => DEFAULT_UNMANAGED_TASKS_STATES.includes(state));

        if (!isEmpty(unManagedStates)) {
          const dateTime = action?.datetime || filters?.active?.datetime;
          const unManagedStateFilters = {};

          unManagedStates.forEach((state) => {
            const startDate = dateTime[0] ? dateTime[0].format() : moment().startOf("day").format();
            const endDate = dateTime[1] ? dateTime[1].format() : moment().endOf("day").format();

            unManagedStateFilters[state] = [
              { key: `${state}_at`, condition: "__gte", value: startDate },
              { key: `${state}_at`, condition: "__lt", value: endDate },
              { key: "ordering", condition: "", value: `${state}_at` },
            ];
          });

          onApplyRemoteFilters(unManagedStateFilters);
        }

        break;
      case "FILTER_BY_DATE":
        // in localstorage we store datetime as string, which creates the need for parsing the dates.
        if (action.datetime && action.datetime.length > 0) {
          if (typeof action.datetime[0] === "string") {
            action.datetime.map((d, i) => {
              action.datetime[i] = parseDate(d);
            });
          }
        }

        filters.active.datetime = action.datetime;
        setFilters({ ...filters });

        const dateUnManagedStates = filters.active.states.filter((state) =>
          DEFAULT_UNMANAGED_TASKS_STATES.includes(state)
        );

        if (!isEmpty(dateUnManagedStates)) {
          const dateTime = filters.active.datetime;
          const unManagedStateFilters = {};

          dateUnManagedStates.forEach((state) => {
            const startDate = dateTime?.[0] ? dateTime[0].format() : moment().startOf("day").format();
            const endDate = dateTime?.[1] ? dateTime[1].format() : moment().endOf("day").format();

            unManagedStateFilters[state] = [
              { key: `${state}_at`, condition: "__gte", value: startDate },
              { key: `${state}_at`, condition: "__lt", value: endDate },
              { key: "ordering", condition: "", value: `${state}_at` },
            ];
          });

          onApplyRemoteFilters(unManagedStateFilters);
        }

        break;
      case "FILTER_BY_METAFIELDS":
        filters.active["metaFields"] = action.metaFields;
        setFilters({ ...filters });

        break;
      case "FILTER_BY_ASSIGNEES":
        filters.active["assignees"] = action.assignees;
        setFilters({ ...filters });

        break;
      default:
        console.error("error: invalid filter type specified", action.type);
    }

    if (!isEmpty(filters?.active)) {
      localStorage.setItem(filterKey, JSON.stringify(filters.active)); // Persist filters params to local storage
    }
    setLoading(false);
  };

  // Apply filters on tasks...
  const tasksResults = useCallback(
    (tasks) => {
      let results = tasks;

      Object.keys(filters.active).map((key) => {
        switch (key) {
          case "states":
            // trigger api filters when un-managed states is detected and date is set
            if (!isEmpty(filters.active[key])) {
              results = results.map((group) => {
                const filtered = [];

                group.tasks.map((task) => {
                  if (filters.active[key] && filters.active[key].includes(task.state)) {
                    filtered.push(task);
                  }
                });
                group.tasks = filtered;
                return group;
              });
            }

            break;
          case "datetime":
            if (!isEmpty(filters.active["datetime"])) {
              tasks.forEach((group) => {
                const filtered = [];

                group.tasks.forEach((task) => {
                  const isInUnManagedState = DEFAULT_UNMANAGED_TASKS_STATES.includes(task.state);
                  if (isInUnManagedState) {
                    filtered.push(task);
                    return;
                  }

                  switch (true) {
                    case filters.active[key].length === 2:
                      // Handle bounds
                      if (
                        filters.active[key][1] &&
                        filters.active[key][1].isAfter(task.complete_after) &&
                        ((filters.active[key][0] && filters.active[key][0].isBefore(task.complete_before)) ||
                          task.complete_before == undefined)
                      ) {
                        filtered.push(task);
                      }
                      break;
                    default:
                      // Handles complete before
                      if (filters.active[key][0] && filters.active[key][0].isBefore(task.complete_before)) {
                        filtered.push(task);
                      }

                      // Handles complete after
                      if (filters.active[key][1] && filters.active[key][1].isAfter(task.complete_after)) {
                        filtered.push(task);
                      }
                  }
                });
                group.tasks = filtered;
                return group;
              });
            }

            break;
          case "metaFields":
            if (!isEmpty(filters.active["metaFields"])) {
              results = tasks.map((group) => {
                const filtered = [];

                group.tasks.map((task) => {
                  const taskMetaFields = task?.metafields;
                  // Check if task matches all metafield filters
                  const matchesAllFilters = filters.active["metaFields"].every((filter) => {
                    if (isEmpty(filter.value) || isEmpty(filter.key)) {
                      return true; // Skip empty filters
                    }

                    const metaField = taskMetaFields?.[filter.key]?.toString();

                    if (!metaField) {
                      return false;
                    }

                    if (isArray(filter.value)) {
                      return filter.value.includes(metaField);
                    }

                    return metaField.toLowerCase().startsWith(filter.value.toLowerCase());
                  });

                  if (matchesAllFilters) {
                    filtered.push(task);
                  }
                });
                group.tasks = filtered;
                return group;
              });
            }
            break;
          case "assignees":
            if (!isEmpty(filters.active["assignees"])) {
              results = filter(
                tasks,
                (group) =>
                  filters.active[key].includes(extractUuidFromUrl(group.assignee.user)) ||
                  filters.active[key].includes(group.assignee.id) ||
                  group.assignee.id === UNASSIGNED_ID
              );
            }
            break;
          default:
            console.error(`invalid key ${key} received `);
        }
      });
      return results;
    },
    [filters.active]
  );

  return { filteredTasks: tasksResults(tasks), filters, onApply, loadingFilters: loading };
};

export default useStoreFilters;
