import { ReactNode } from "react";

import { t } from "@lingui/macro";

export enum DATA_TYPE {
  CHOICE = "choice",
  BOOLEAN = "boolean",
  TEXT = "text",
  NUMBER = "number",
  ARRAY = "array",
  DATETIME = "date-time",
  TIME = "time",
  DATE = "date",
  OBJECT = "object",
  NULL = "null",
  CUSTOM = "custom",
}

export enum FILTER_CONDITION {
  TRUE = "true",
  FALSE = "false",
  LTE = "lte",
  LT = "lt",
  GTE = "gte",
  GT = "gt",
  EQUALS = "=",
  CONTAINS = "contains",
  ICONTAINS = "icontains",
  IN = "in",
  ISNULL = "isnull",
}

export const useFilterConditionLabels = () => {
  return {
    true: t`true`,
    false: t`false`,
    lte: t`less than or equals to`,
    lt: t`less than`,
    gte: t`greater than or equals to`,
    gt: t`greater than`,
    "=": t`equals`,
    icontains: t`case-insensitive contains`,
    contains: t`contains`,
    search: t`search`,
    iregex: t`regex`,
    isnull: t`is empty`,
    istartswith: t`starts with`,
    in: t`in`,
  };
};

export enum ORDERING {
  ASC = "asc",
  DESC = "desc",
}

export interface SortOrder {
  key: string;
  order?: ORDERING;
}

// TODO use another interface for omit and perform a union between types
export interface Column {
  width?: number;
  type?: DATA_TYPE;
  title?: string;
  key?: string;
  options?: Options[];
  group?: string;
  icon?: ReactNode;
  defaultIfNull?: ReactNode;
  render?: (data) => ReactNode;
  isVisible?: boolean; // handles if column is visible or not
  isSortable?: boolean; // indicates if table can be sorted based on this column
  defaultSort?: SortOrder;
  canCopy?: boolean;
  canResize?: boolean;
  filter?: {
    omit?: boolean;
    type?: DATA_TYPE; // TODO retire type function after refactoring.
    conditions?: FILTER_CONDITION[];
    values?: FilterOptions[]; // rename to options
    isQuick?: boolean;
    defaultValue?: any;
  };
  dataIndex?: string[];
  omit?: boolean;
  fixed?: boolean;
  exportable?: boolean;
  export_key?: string;
}

interface FilterOptions {
  label: ReactNode;
  value: string | number;
  inactiveAt?: string;
}

export interface Record {
  [key: string]: ReactNode;
}

export interface Options {
  label: string;
  value: string;
}
