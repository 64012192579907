import { useState } from "react";

import { t, Trans } from "@lingui/macro";
import { Col, Dropdown, Menu, Row, Typography } from "antd";
import styled from "styled-components";

import { DummyTask } from "components/dashboard/tasks/task";
import ContextMenu, { MenuProps } from "components/menus/context-menu";
import DownIcon from "icons/down";
import EllipsesIcon from "icons/ellipses";
import FolderClosedIcon from "icons/folder-closed";
import FolderOpenIcon from "icons/folder-open";
import UpIcon from "icons/up";
import { TaskSizeBadge, TaskSizeTotals } from "./utils";
import { AccountRole } from "types/type";

const RouteContainer = styled.div<{ empty: boolean; opened: boolean }>`
  color: #ffffff;
  padding: 4px 8px;

  .ant-typography {
    color: #ffffff;
  }
`;

const DropZone = styled.div`
  text-align: center;
  background: linear-gradient(to right, transparent 33%, #2a2f39 0%) top/10px 1px repeat-x,
    linear-gradient(transparent 33%, #2a2f39 0%) right/1px 10px repeat-y,
    linear-gradient(to right, transparent 33%, #2a2f39 0%) bottom/10px 1px repeat-x,
    linear-gradient(transparent 33%, #2a2f39 0%) left/1px 10px repeat-y;

  padding: 8px 12px;
  margin: 8px 0;
  border-radius: 5px;

  .ant-typography {
    color: #c4c4c4 !important;
    font-weight: 400;
    font-size: 14px;
  }
`;

const TaskCount = styled.span`
  background: #2a2f39;
  border-radius: 2px;
  padding: 0 4px !important;
  color: #c4c4c4;
  line-height: 12px;
`;

const OpenCloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FolderIcon = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledEllipsesIcon = styled.div`
  padding: 0 3px;
`;

const Routes = ({
  assigneeId,
  groups,
  isOpened,
  title,
  count,
  onToggleRoute,
  items = [],
  routeKey,
  state,
  accountRole,
  onReorder,
  setNodeData = undefined,
  taskSize,
}: {
  accountRole?: AccountRole;
  assigneeId: string;
  groups: any;
  isOpened: boolean;
  title: string;
  count: string;
  onToggleRoute: () => void;
  items: MenuProps[];
  routeKey?: string;
  state: string;
  onReorder?: () => void;
  setNodeData?: (k: string) => void;
  taskSize?: TaskSizeTotals;
}) => {
  const [openContextMenu, setOpenContextMenu] = useState(false);

  const onDragOver = () => {
    if (setNodeData) {
      setNodeData(routeKey);
    }

    if (routeKey) {
      document
        .getElementById(routeKey)
        ?.setAttribute(
          "style",
          "background: linear-gradient(to right, transparent 33%, #ffffff 0%) top/10px 1px repeat-x, " +
            "linear-gradient(transparent 33%, #ffffff 0%) right/1px 10px repeat-y," +
            "linear-gradient(to right, transparent 33%, #ffffff 0%) bottom/10px 1px repeat-x," +
            "linear-gradient(transparent 33%, #ffffff 0%) left/1px 10px repeat-y;"
        );
    }
  };

  const onDragLeave = () => {
    if (routeKey) {
      document
        .getElementById(routeKey)
        ?.setAttribute(
          "style",
          "background:  linear-gradient(to right, transparent 33%, #2a2f39 0%) top/10px 1px repeat-x, " +
            "linear-gradient(transparent 33%, #2a2f39 0%) right/1px 10px repeat-y," +
            "linear-gradient(to right, transparent 33%, #2a2f39 0%) bottom/10px 1px repeat-x," +
            "linear-gradient(transparent 33%, #2a2f39 0%) left/1px 10px repeat-y;"
        );
    }
  };
  const onDone = () => {
    onDragLeave();

    if (routeKey) {
      onReorder();
    }
  };

  return (
    <ContextMenu key={assigneeId} items={items} rowData={groups}>
      <RouteContainer
        onClick={() => {
          onToggleRoute();
        }}
        empty={count === ""}
        opened={isOpened}
      >
        <Row gutter={8} align={"middle"} justify="space-between">
          <Col>
            <Row gutter={8} align={"middle"}>
              <Col>
                <Row justify={"center"} align={"middle"}>
                  <OpenCloseIcon> {!isOpened ? <UpIcon /> : <DownIcon />}</OpenCloseIcon>
                </Row>
              </Col>
              <Col>
                <Row justify={"center"} align={"middle"}>
                  <FolderIcon>{isOpened ? <FolderOpenIcon /> : <FolderClosedIcon />}</FolderIcon>
                </Row>
              </Col>
              <Col>
                <Typography.Text italic={state === "draft"}>
                  {title} {state === "draft" && t`(draft)`}
                </Typography.Text>
              </Col>
              {count && (
                <Col>
                  <TaskCount>{count}</TaskCount>
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              {taskSize && accountRole?.show_vehicle_capacity && <TaskSizeBadge taskSize={taskSize} />}
              <Dropdown
                overlay={
                  <Menu>
                    {items.map((item: any, i) => {
                      if (item.cascade) {
                        return (
                          <Menu.SubMenu title={item.label} key={item.label + i}>
                            {item.cascade.map((data, j) => {
                              return (
                                <Menu.Item
                                  key={data.key + j}
                                  disabled={data?.disabled}
                                  onClick={(e) => {
                                    e.domEvent.preventDefault();
                                    e.domEvent.stopPropagation();

                                    data.action();
                                    setOpenContextMenu(false);
                                  }}
                                >
                                  {data.label}
                                </Menu.Item>
                              );
                            })}
                          </Menu.SubMenu>
                        );
                      }

                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={(e) => {
                            e.domEvent.preventDefault();
                            e.domEvent.stopPropagation();

                            item.action();
                            setOpenContextMenu(false);
                          }}
                          disabled={item.disabled}
                        >
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
                trigger={["click", "contextMenu"]}
                open={openContextMenu}
                onOpenChange={(e) => {
                  setOpenContextMenu(e);
                }}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <StyledEllipsesIcon>
                    <EllipsesIcon />
                  </StyledEllipsesIcon>
                </a>
              </Dropdown>
            </div>
          </Col>
        </Row>
        {isOpened && count === "" ? (
          <DropZone onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDone} id={routeKey}>
            <Typography.Text>
              <Trans>Drag tasks that you want to group here.</Trans>
            </Typography.Text>
          </DropZone>
        ) : (
          <DummyTask taskKey={routeKey} setNodeData={setNodeData} onReorder={onReorder} />
        )}
      </RouteContainer>
    </ContextMenu>
  );
};

export { Routes };
